import Dialog from "@stimulus-components/dialog"

export default class extends Dialog {
  static targets = ['form', 'reason']

  open(event) {
    super.open()
    this.formTarget.action = event.detail.url
    this.reasonTarget.value = ''
  }
}

