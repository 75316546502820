import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    url: String
  }
  pressed() {
    const event = new CustomEvent('exclude-button-pressed', {
      detail: { url: this.urlValue }
    })
    window.dispatchEvent(event)
  }
}


